










































import { Component, Prop, Vue } from 'vue-property-decorator';
import Transaction from '@/models/Transaction';
import moment from 'moment';

@Component({
    components: {
        EditDriverComponent: () => import(
            /* webpackChunkName: "EditDriverComponent" */
            '@/components/EditDriver.component.vue')
    }
})
export default class TransactionTableComponent extends Vue {
    @Prop({ required: true })
    public transactions!: Transaction[];

    @Prop({ required: true, default: false })
    private isLoading!: boolean;

    @Prop({ required: false, default: '' })
    private search!: string;

    public tableHeaders = [{
        text: this.$t('TRANSACTIONS.TABLE.DRIVER'),
        value: 'driver',
        sortable: true
    }, {
        text: this.$t('TRANSACTIONS.TABLE.PAYMENT_METHOD'),
        value: 'paymentMethod',
        sortable: false
    }, {
        text: this.$t('TRANSACTIONS.TABLE.MANAGER'),
        value: 'manager.login',
        sortable: true
    }, {
        text: this.$t('TRANSACTIONS.TABLE.TIME'),
        value: 'createdAt',
        sortable: true
    }, {
        text: this.$t('TRANSACTIONS.TABLE.DESCRIPTION'),
        value: 'description',
        sortable: false
    }, {
        text: this.$t('TRANSACTIONS.TABLE.AMOUNT'),
        value: 'amount',
        sortable: true
    }];

    /**
     * sorts the list of items, currently the list is only sorted by status
     * @param items
     * @param index
     * @param isDescending
     * @private
     */
    private customSort(items: any[], index: string[], isDescending: boolean[]) {
        items.sort((a: Transaction, b: Transaction) => {
            const aMoment = moment(a.createdAt);
            const bMoment = moment(b.createdAt);

            switch (index[0]) {
                case 'driver':
                    if (isDescending[0]) return this.driverName(a) ? 1 : -1;
                    else return this.driverName(b) ? -1 : 1;
                case 'manager.login':
                    if (isDescending[0]) return b.manager.login.localeCompare(a.manager.login);
                    else return a.manager.login.localeCompare(b.manager.login);
                case 'amount':
                    if (isDescending[0]) return b.amount - a.amount;
                    else return a.amount - b.amount;
                case 'createdAt':
                    if (isDescending[0]) return bMoment.diff(aMoment);
                    else return aMoment.diff(bMoment);
                default:
                    return 1;
            }
        });

        return items;
    }

    private customFilter(value: any, search: string, item: Transaction) {
        return this.driverName(item).toLowerCase().includes(search.toLowerCase());
    }

    private get transactionList() {
        return this.transactions.sort((a: Transaction, b: Transaction) => {
            const timeA = moment(a.createdAt, moment.ISO_8601, true);
            const timeB = moment(b.createdAt, moment.ISO_8601, true);
            return timeB.diff(timeA);
        });
    }

    private driverName(item: Transaction): string {
        if (!item.driver.firstName || !item.driver.lastName) return '-';
        return `${item.driver.firstName} ${item.driver.lastName}`;
    }

    private managerName(item: Transaction): string {
        if (!item.manager?.login) return this.$t('TRANSACTIONS.DELETED_MANAGER').toString();
        return item.manager.login;
    }

    private formatDate(value: string): string {
        const date = moment(value, moment.ISO_8601, true);
        return date.format('DD.MM.yyyy HH:mm');
    }
}

