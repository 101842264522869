var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"driver-table"},[_c('v-card',{staticClass:"box-standard-shadow table-container py-1"},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"items":_vm.transactionList,"headers":_vm.tableHeaders,"custom-sort":_vm.customSort,"loading":_vm.isLoading,"custom-filter":_vm.customFilter,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.driverName(item))+" ")])]}},{key:"item.manager.login",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'deleted-manager': !item.manager}},[_vm._v(_vm._s(_vm.managerName(item)))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description)?_c('span',[_vm._v(_vm._s(item.description))]):_c('span',[_vm._v("-")])]}},{key:"item.paymentMethod",fn:function(ref){
var item = ref.item;
return [(item.paymentMethod)?_c('span',[_vm._v(_vm._s(_vm.$t(("TRANSACTIONS.PAYMENT_METHOD." + (item.paymentMethod)))))]):_c('span',[_vm._v("-")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount)+" NAD")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }